import React from "react";
import Layout from "../components/layout";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { Col, Container, Row } from "react-bootstrap";
import { AiFillInstagram, AiFillFacebook } from "react-icons/ai";
import Logo from "../components/logo-white";
import SEO from "../components/seo";

import styles from "../styles/about.module.scss";

const Index = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "landing/landing-background.png" }) {
        childImageSharp {
          fluid(maxWidth: 690, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="About Us" article={false} pathname={`/about`} />
      <Container fluid className="p-0">
        <Row className="justify-content-center align-items-center">
          <Col className={styles.leftCol}>
            <Img
              fluid={data.file.childImageSharp.fluid}
              style={{ width: "100%", height: "100%", position: "relative" }}
              className={styles.image}
            />
            <div className={styles.logo}>
              <Logo />
            </div>
          </Col>
          <Col sm={12} lg={6} className={`mt-5 lg-mt-0 p-5 ${styles.rightCol}`}>
            <h1 className={`${styles.title} mb-4 display-4`}>
              About <span>Blazen</span>
            </h1>
            <p className="text-center fs-4">
              Established in 2019, Blazen Supplements has focused on bringing
              down the best supplements from around the world ever since.
            </p>

            <p className="text-center fs-4">
              With customer satisfaction as our top priority, we ensure that our
              customers receive authentic products of the highest quality needed
              to achieve their fitness goals.
            </p>

            <div className={`text-center ${styles.social}`}>
              <a
                href="https://www.facebook.com/blazensupplements/"
                target="_blank"
                rel="noreferrer"
              >
                <AiFillFacebook />
              </a>
              <a
                href="https://www.instagram.com/blazen_supplements/"
                target="_blank"
                rel="noreferrer"
              >
                <AiFillInstagram />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Index;
